import React, { useEffect, useState } from 'react';
import * as images from './imgs/exportImages';

function MainPage() {

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    const defaultTheme = "light";
    const themeToLoad = savedTheme ? savedTheme : defaultTheme;
    setTheme(themeToLoad);

    const themeSwitcher = document.querySelector("#ThemeSwitcher");
    themeSwitcher.addEventListener("click", toggleTheme);

    return () => {
      themeSwitcher.removeEventListener("click", toggleTheme);
    };
  }, []);

  const setTheme = (theme) => {
    localStorage.setItem("theme", theme);
    document.documentElement.setAttribute("data-theme", theme);
    updateButtonIcon(theme);
  };

  const toggleTheme = () => {
    const currentTheme = document.documentElement.getAttribute("data-theme");
    setTheme(currentTheme === "dark" ? "light" : "dark");
  };

  const updateButtonIcon = (theme) => {
    const themeIcon = document.getElementById("ThemeIcon");
    if (theme === "dark") {
      themeIcon.innerHTML = `
        <path strokeLinecap="round" strokeLinejoin="round" d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z"></path>
      `;
    } else {
      themeIcon.innerHTML = `
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"></path>
      `;
    }
  };

  useEffect(() => {
    const MobileBTN = document.getElementById("MobileBTN");
    const MobileMenuPos = document.getElementById("MobileMenuPos");

    const handleClick = (event) => {
      if (!MobileMenuPos.contains(event.target) && !MobileBTN.contains(event.target)) {
        MobileMenuPos.classList.remove("MenuShow");
      }
    };

    const handleMenu = () => {
      MobileMenuPos.classList.toggle("MenuShow");
    };

    MobileBTN.addEventListener('click', handleMenu);
    document.addEventListener('click', handleClick);

    return () => {
      MobileBTN.removeEventListener('click', handleMenu);
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    const loaderBox = document.getElementById('LoaderBox');
    if (pageLoaded) {
      loaderBox.classList.add('LoaderHide');
    }
  }, [pageLoaded]);

  useEffect(() => {
    window.addEventListener('load', () => {
      setPageLoaded(true);
    });
  }, []);



  return (
    <>

      <div className='LoaderBox' id='LoaderBox'>
      <div class="container">
  <div class="cube"><div class="cube__inner"></div></div>
  <div class="cube"><div class="cube__inner"></div></div>
  <div class="cube"><div class="cube__inner"></div></div>
</div>

      </div>

      <div className="HeaderBox">
        <header>
          <ul>
            <a href="#">Գլխավոր</a>
            <a href="#Products">Պրոդուկտներ</a>
            <a href="#Playlists">BOMB Վիդեոներ</a>
            <a href="#FanMeeting">Ֆան Հանդիպում 2024</a>
          </ul>
          <button className='HeaderBTN' id="MobileBTN"><i className="fa-regular fa-bars"></i></button>
          <span className="HeaderLine"></span>
          <div className="HeaderButtons">
            <button id="ThemeSwitcher" className="HeaderBTN">
              <svg id="ThemeIcon" data-slot="icon" data-darkreader-inline-stroke="" fill="none" strokeWidth="1.9"
                stroke="currentColor" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round"
                  d="M21.752 15.002A9.72 9.72 0 0 1 18 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 0 0 3 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 0 0 9.002-5.998Z">
                </path>
              </svg>
            </button>
          </div>
        </header>
      </div>

      <div className='MobileMenuPos' id='MobileMenuPos'>
        <div className='MobileMenu'>
          <ul>
            <a href="#">Գլխավոր</a>
            <a href="#Products">Պրոդուկտներ</a>
            <a href="#Playlists">BOMB Վիդեոներ</a>
            <a href="#FanMeeting">Ֆան Հանդիպում 2024</a>
          </ul>
        </div>
      </div>

      <div className='MainSection'>
        <video className='VideoBG' playsInline autoPlay muted loop poster=''>
          <source src={images.testVideo} type='video/mp4' />
        </video>
        <div className='MainSectionElements'>
          <div className='MainSectionDiv1'>
            <h1 className='MainSectionText'>Saint-Hov</h1>
            <p className='MainSectionTextInfo'>Հովհաննես Ասատրյան, կիբեր սպորտսմեն և նախկին չեմպիոն. լավագույն հայ գեյմերը, սթրիմերը և բլոգերը:</p>
            <div className='MainSectionButtons'>
              <a href="https://www.youtube.com/saint-hov" target='__blank'><button className='MainSB mb1'>Youtube Ալիք</button></a>
              <a href="/#Products"><button className='MainSB mb2'>Պրոդուկտներ</button></a>
            </div>
          </div>
          <img src={images.saintHov} alt="SaintHovImage" className='SaintHovImg' />
        </div>
      </div>
      <div className='SponsorsCarouselMainBox'>
        <div className="logos">
          <div className="logos-slide">
            <img src={images.Sponsor1} alt='Sponsor'/>
            <img src={images.Sponsor2} alt='Sponsor'/>
            <img src={images.Sponsor3} alt='Sponsor'/>
            <img src={images.Sponsor4} alt='Sponsor'/>
            <img src={images.Sponsor5} alt='Sponsor'/>
            <img src={images.Sponsor6} alt='Sponsor'/>
            <img src={images.Sponsor7} alt='Sponsor'/>
          </div>

          <div className="logos-slide">
            <img src={images.Sponsor1} alt='Sponsor'/>
            <img src={images.Sponsor2} alt='Sponsor'/>
            <img src={images.Sponsor3} alt='Sponsor'/>
            <img src={images.Sponsor4} alt='Sponsor'/>
            <img src={images.Sponsor5} alt='Sponsor'/>
            <img src={images.Sponsor6} alt='Sponsor'/>
            <img src={images.Sponsor7} alt='Sponsor'/>
          </div>
        </div>
      </div>
      <div className='ProductsSection' id='Products'>
        <p className='SectionName'><span>Բոմբի նման Պրոդուկտներ</span></p>
        <div className='ProductBoxes'>
          <div className='ProductBox'>
            <a href="https://zangakbookstore.am/glkhark-sev-bombi-nman" target='__blank'>
              <img src={images.ProductCapBlack} alt="Product" className='ProductIMG' />
              <p className='ProductName'>Գլխարկ սև - Բոմբի նման</p>
              <p className='ProductPrice'>5900 դրամ</p>
              <button className='ProductBuyBTN'><i className="fa-regular fa-cart-shopping"></i> Գնել</button>
            </a>
          </div>
          <div className='ProductBox'>
            <a href="https://zangakbookstore.am/glkhark-spitak-bombi-nman" target='__blank'>
              <img src={images.ProductCapWhite} alt="Product" className='ProductIMG' />
              <p className='ProductName'>Գլխարկ սպիտակ - Բոմբի նման</p>
              <p className='ProductPrice'>5900 դրամ</p>
              <button className='ProductBuyBTN'><i class="fa-regular fa-cart-shopping"></i> Գնել</button>
            </a>
          </div>
          <div className='ProductBox'>
            <a href="https://zangakbookstore.am/shapik-bombi-nman-xs" target='__blank'>
              <img src={images.ProductShirt} alt="Product" className='ProductIMG' />
              <p className='ProductName'>Շապիկ - Բոմբի նման XS</p>
              <p className='ProductPrice'>7900 դրամ</p>
              <button className='ProductBuyBTN'><i class="fa-regular fa-cart-shopping"></i> Գնել</button>
            </a>
          </div>
          <div className='ProductBox'>
            <a href="https://zangakbookstore.am/shapik-bombi-nman-l" target='__blank'>
              <img src={images.ProductShirt} alt="Product" className='ProductIMG' />
              <p className='ProductName'>Շապիկ - Բոմբի նման Լ</p>
              <p className='ProductPrice'>7900 դրամ</p>
              <button className='ProductBuyBTN'><i class="fa-regular fa-cart-shopping"></i> Գնել</button>
            </a>
          </div>
          <div className='ProductBox'>
            <a href="https://zangakbookstore.am/shapik-bombi-nman-s" target='__blank'>
              <img src={images.ProductShirt} alt="Product" className='ProductIMG' />
              <p className='ProductName'>Շապիկ - Բոմբի նման S</p>
              <p className='ProductPrice'>7900 դրամ</p>
              <button className='ProductBuyBTN'><i class="fa-regular fa-cart-shopping"></i> Գնել</button>
            </a>
          </div>
          <div className='ProductBox'>
            <a href="https://zangakbookstore.am/stiqer-bombi-nman" target='__blank'>
              <img src={images.ProductStickers} alt="Product" className='ProductIMG' />
              <p className='ProductName'>Սթիքեր - Բոմբի նման</p>
              <p className='ProductPrice'>690 դրամ</p>
              <button className='ProductBuyBTN'><i class="fa-regular fa-cart-shopping"></i> Գնել</button>
            </a>
          </div>
        </div>
      </div>
      <div className='VideosSections' id='Playlists'>
        <p className='SectionName'><span>Բոմբի նման Վիդեոներ</span></p>
        <div className='ProductBoxes'>
          <div className='PlaylistBox'>
            <a href="https://www.youtube.com/watch?v=5lehuCBIFrw&list=PL2ldEgQARkm8sDuVJqdRamZKxd0qLmwP9&pp=iAQB" target='__blank'>
              <img src={images.atomicHeart} alt="AtomicHeart" />
              <p className='PlaylistName'>Atomic Heart</p>
              <button className='PlaylistBTN'>Դիտել հավաքածուն</button>
            </a>
          </div>
          <div className='PlaylistBox'>
            <a href="https://www.youtube.com/watch?v=Af_bCHrPPzU&list=PL2ldEgQARkm9mu-sEDyKMM276g-vWOvu6&pp=iAQB" target='__blank'>
              <img src={images.stray} alt="AtomicHeart" />
              <p className='PlaylistName'>Stray</p>
              <button className='PlaylistBTN'>Դիտել հավաքածուն</button>
            </a>
          </div>
          <div className='PlaylistBox'>
            <a href="https://www.youtube.com/watch?v=ndfkNvZIbpg&list=PL2ldEgQARkm_gAuSAp_qYfS_jHPSQRKG4&pp=iAQB" target='__blank'>
              <img src={images.detroid} alt="AtomicHeart" />
              <p className='PlaylistName'>Detroid Become Human</p>
              <button className='PlaylistBTN'>Դիտել հավաքածուն</button>
            </a>
          </div>
          <div className='PlaylistBox'>
            <a href="https://www.youtube.com/watch?v=jHPO1UuqxvU&list=PL2ldEgQARkm8U7JaRuUZxnab2_zvqHJ7p&pp=iAQB" target='__blank'>
              <img src={images.beyond} alt="AtomicHeart" />
              <p className='PlaylistName'>Beyond Two Souls</p>
              <button className='PlaylistBTN'>Դիտել հավաքածուն</button>
            </a>
          </div>
          <div className='PlaylistBox'>
            <a href="https://www.youtube.com/watch?v=_Aa56IP-CNQ&list=PL2ldEgQARkm86o6ZuXiaa4CzGurrCB8nC&pp=iAQB" target='__blank'>
              <img src={images.farcry6} alt="AtomicHeart" />
              <p className='PlaylistName'>Far Cry 6</p>
              <button className='PlaylistBTN'>Դիտել հավաքածուն</button>
            </a>
          </div>
          <div className='PlaylistBox'>
            <a href="https://www.youtube.com/watch?v=62-IGK-PQjk&list=PL2ldEgQARkm-mkunXj5zZ_Yz1CvOobqjO&pp=iAQB" target='__blank'>
              <img src={images.thequarry} alt="AtomicHeart" />
              <p className='PlaylistName'>The Quarry</p>
              <button className='PlaylistBTN'>Դիտել հավաքածուն</button>
            </a>
          </div>
          <div className='PlaylistBox'>
            <a href="https://www.youtube.com/watch?v=wHw4wdeV3ZE&list=PL2ldEgQARkm_8mR0LykwOvmN_HYx4O4qr&pp=iAQB" target='__blank'>
              <img src={images.godofwar} alt="AtomicHeart" />
              <p className='PlaylistName'>God Of War</p>
              <button className='PlaylistBTN'>Դիտել հավաքածուն</button>
            </a>
          </div>
          <div className='PlaylistBox'>
            <a href="https://www.youtube.com/watch?v=NB-5JUBZgvQ&list=PL2ldEgQARkm-Gf_efbZwOjE-j0ShOY123&pp=iAQB" target='__blank'>
              <img src={images.bombxaxer} alt="AtomicHeart" />
              <p className='PlaylistName'>Bomb Վիդեոներ</p>
              <button className='PlaylistBTN'>Դիտել հավաքածուն</button>
            </a>
          </div>
        </div>
        <a href="https://www.youtube.com/@SaintHov/playlists" target='__blank'><button className='VideoSectionBTN'>Ամբողջ Հավաքածուները</button></a>
      </div>
      <div className='FanMeetingSection' id='FanMeeting'>
        <p className='SectionName'><span>Ֆան Հանդիպում 2024</span></p>
        <div className='FanVideosBox'>
          <iframe width="500" height="280" src="https://www.youtube.com/embed/kgIWnP6-1-I" title="Հազարավոր ֆաներ // Ֆան հանդիպում 2024 // DALMA" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
          <iframe width="500" height="280" src="https://www.youtube.com/embed/Zd3YZJfiXz0" title="🎁ԲԱՑՈՒՄ ԵՄ ՁԵՐ ՆՎԵՐՆԵՐԸ🔥💣ՖԱՆ ՀԱՆԴԻՊՈՒՄ" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
        <div className='FanPhotosBox'>
          <img src={images.fm1} alt="fan meeting" className='FanPhoto' />
          <img src={images.fm2} alt="fan meeting" className='FanPhoto' />
          <img src={images.fm3} alt="fan meeting" className='FanPhoto' />
        </div>
      </div>
        <footer>
          <p className='CopyRightText'>Բոլոր իրավունքները պաշտպանված են © 2024</p>
          <p className='MadeBy'>Պատրաստված է 💗 <a href="https://www.youtube.com/@Official-AroCodes?sub_confirmation=1">AroCodes</a>-ի կողմից</p>
        </footer>
    </>
  )
}

export default MainPage