import React from 'react';
import ReactDOM from 'react-dom/client';
import './main.css';
import MainPage from './MainPage';

const root = ReactDOM.createRoot(document.querySelector('root'));
root.render(
  <React.StrictMode>
    <MainPage/>
  </React.StrictMode>
);